import request from "@/api/config.js";

export function updateProject(id, data) {
  return request({
    url: `project/${id}`,
    method: "put",
    data,
  });
}

export function findByIdProject(id) {
  return request({
    url: `project/${id}`,
    method: "get",
  });
}

export function getAllProject() {
  return request({
    timeout: 15000,
    url: `project`,
    method: "get",
  });
}

export function findAllAdmin() {
  return request({
    timeout: 60000,
    url: `project/find/All-Admin`,
    method: "get",
  });
}

export function getProjectListByUser(id) {
  return request({
    url: `project/${id}/project-list-by-user`,
    method: "get",
  });
}

export function getStudentProject() {
  return request({
    url: `project/all`,
    method: "get",
  });
}

export function createProject(data) {
  return request({
    url: `project/create`,
    method: "post",
    data,
  });
}

export function addProgrammers(id, data) {
  return request({
    url: `project/${id}/add-programmers`,
    method: "post",
    data,
  });
}

export function removeProgrammers(id, data) {
  return request({
    url: `project/${id}/remove-programmers`,
    method: "post",
    data,
  });
}

export function addFollowers(id, data) {
  return request({
    url: `project/${id}/add-followers`,
    method: "post",
    data,
  });
}

export function updateFollowers(project_id, data) {
  return request({
    url: `project/${project_id}/update-followers`,
    method: "post",
    data,
  });
}

export function deleteProject(id) {
  return request({
    url: `project/${id}`,
    method: "delete",
  });
}
