import request from "@/api/config.js";

export function getAllRequests() {
  return request({
    url: `company-request`,
    method: "get",
  });
}
export function getRequestById(id) {
  return request({
    url: `company-request/${id}`,
    method: "get",
  });
}

export function createRequest(data) {
  return request({
    url: `company-request/create`,
    method: "post",
    data,
  });
}

export function acceptRequest(id, data) {
  return request({
    url: `company-request/accept/${id}`,
    method: "post",
    data,
  });
}

export function referralRegistration(data) {
  return request({
    url: `company-request/referralRegistration`,
    method: "post",
    data,
  });
}

export function updateRequest(id, data) {
  return request({
    url: `company-request/${id}`,
    method: "put",
    data,
  });
}

export function deleteRequest(id) {
  return request({
    url: `company-request/${id}`,
    method: "delete",
  });
}

export function getPartnersList(company_id) {
  return request({
    url: `company-request/${company_id}/partners/list`,
    method: "get",
  });
}
