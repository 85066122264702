import request from "@/api/config.js";

export function GOSData(data) {
  return request({
    url: `landing/GOSData`,
    method: "post",
    data,
  });
}

export function getRegisteredUsersCounter() {
  return request({
    url: `landing/get/users-counter`,
    method: "get",
  });
}
