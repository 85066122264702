import request from "@/api/config.js";

export function login(credentials) {
  return request({
    url: `auth/login`,
    method: "post",
    data: credentials,
  }).then((response) => response.data);
}

export function signUp(credentials) {
  return request({
    url: `auth/registration`,
    method: "post",
    data: credentials,
  }).then((response) => response.data);
}

export function logout() {
  return request({
    url: `auth/logout`,
    method: "post",
  }).then((response) => response.data);
}

export function accessforgot() {
  return request({
    url: `auth/accessforgot`,
    method: "get",
  }).then((response) => response.data);
}

export function reset(credentials) {
  return request({
    url: `auth/reset`,
    method: "post",
    data: credentials,
  }).then((response) => response.data);
}

export function forgot(credentials) {
  return request({
    url: `auth/forgot`,
    method: "post",
    data: credentials,
  }).then((response) => response.data);
}

export function refresh() {
  return request({
    url: `auth/refresh`,
    params: {
      timestamp: new Date().getTime(),
    },
    method: "get",
  }).then((response) => response.data);
}

export function sendQuestion(data) {
  return request({
    url: `auth/sendQuestion`,
    method: "post",
    data: data,
  }).then((response) => response.data);
}

export function sendActivationForUser(data) {
  return request({
    url: `auth/sendActivationForUser`,
    method: "post",
    data: data,
  }).then((response) => response.data);
}
